import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const ExpertMember: FC = () => {
  const data: GatsbyExpertMemberComponentQuery = useStaticQuery(graphql`
    query ExpertMemberComponent {
      ryotaK: file(relativePath: { eq: "en/face_ryotaK.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      shiga: file(relativePath: { eq: "en/face_shiga.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      yoneuchi: file(relativePath: { eq: "en/face_yoneuchi.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      ueno: file(relativePath: { eq: "en/face_ueno.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <section className={styles.ExpertMember}>
      <h2 className={styles.ExpertMember_Heading}>Meet the Experts</h2>
      <div className={styles.ExpertMemberList}>
        <div className={styles.ExpertMemberItem}>
          <div className={styles.ExpertMemberImage}>
            <OptionalGatsbyImage
              className={styles.ExpertMember_Image}
              data={data.ryotaK}
              alt=""
            />
          </div>
          <h2 className={styles.ExpertMember_Name}>RyotaK</h2>
          <p className={styles.ExpertMember_Position}>Security Researcher</p>
          <p className={styles.ExpertMember_Description}>
            RyotaK is a security researcher known for various groundbreaking
            cybersecurity research. His series of work has revealed critical
            vulnerabilities of cloud service providers including GitHub and
            Cloudflare and of essential platforms for engineers like Homebrew
            and PyPI. He’s also known for participating in various live hacking
            events, winning multiple awards, and reporting vulnerabilities to
            many leading companies such as Google, Cloudflare, GitHub,
            Salesforce, and Microsoft.
          </p>
        </div>
        <div className={styles.ExpertMemberItem}>
          <div className={styles.ExpertMemberImage}>
            <OptionalGatsbyImage
              className={styles.ExpertMember_Image}
              data={data.shiga}
              alt=""
            />
          </div>
          <h2 className={styles.ExpertMember_Name}>Ryota Shiga</h2>
          <p className={styles.ExpertMember_Position}>
            Operating Officer / Professional Service Div. CTO
          </p>
          <p className={styles.ExpertMember_Description}>
            Ryota Shiga is a well-versed security expert. He has revealed 15
            critical vulnerabilities for Linux kernels and VMM (KVM/VirtualBox)
            during the 18 months of his research in Flatt Security, all while
            directing over 100 web penetration testing projects, proving not
            only his extensive security knowledge but his leadership skills as
            well. He has also won a prize in Pwn2Own 2021 for reporting a
            privilege escalation in Ubuntu.
          </p>
        </div>
        <div className={styles.ExpertMemberItem}>
          <div className={styles.ExpertMemberImage}>
            <OptionalGatsbyImage
              className={styles.ExpertMember_Image}
              data={data.yoneuchi}
              alt=""
            />
          </div>
          <h2 className={styles.ExpertMember_Name}>Takashi Yoneuchi</h2>
          <p className={styles.ExpertMember_Position}>Director / CTO</p>
          <p className={styles.ExpertMember_Description}>
            Takashi Yoneuchi is one of the leaders of the cloud and application
            security industry in Japan with over 7 years of experience educating
            and motivating people on this topic. He is the author of Web Browser
            Security and more, with thousands of Japanese readers. He led Team
            Asia at the International Cybersecurity Challenge 2023 as Head
            Captain. He is also a member of the review board for CODE BLUE, the
            largest cybersecurity conference in Japan.
          </p>
        </div>
        <div className={styles.ExpertMemberItem}>
          <div className={styles.ExpertMemberImage}>
            <OptionalGatsbyImage
              className={styles.ExpertMember_Image}
              data={data.ueno}
              alt=""
            />
          </div>
          <h2 className={styles.ExpertMember_Name}>Sen Ueno</h2>
          <p className={styles.ExpertMember_Position}>Director</p>
          <p className={styles.ExpertMember_Description}>
            Sen Ueno, the CEO of Tricorder Co. Ltd, is a penetration tester and
            a cybersecurity educator. He has contributed to the security
            industry for more than a quarter of a century and is the author of
            numerous books. In addition, he is one of the leaders of OWASP Japan
            Chapter. He has also been awarded ISC2 Asia-Pacific Information
            Security Leadership Achievements (ISLA).
          </p>
        </div>
      </div>
    </section>
  );
};
