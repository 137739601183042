// extracted by mini-css-extract-plugin
export var ResearchProject = "index-module--ResearchProject--iXcBi";
export var ResearchProject_Button = "index-module--ResearchProject_Button--XJbyQ";
export var ResearchProject_ButtonWrapper = "index-module--ResearchProject_ButtonWrapper--017Nb";
export var ResearchProject_Category = "index-module--ResearchProject_Category--gPk25";
export var ResearchProject_Heading = "index-module--ResearchProject_Heading--ui2qB";
export var ResearchProject_List = "index-module--ResearchProject_List--P5ohX";
export var ResearchProject_ListItem = "index-module--ResearchProject_ListItem--dm+3j";
export var ResearchProject_ListItemLink = "index-module--ResearchProject_ListItemLink--SeiH8";
export var ResearchProject_ListSection = "index-module--ResearchProject_ListSection--+lhJ7";
export var ResearchProject_Title = "index-module--ResearchProject_Title--MZFci";
export var ResearchProject_X = "index-module--ResearchProject_X--XOooR";
export var ResearchProject_XBg = "index-module--ResearchProject_XBg--K+r-s";
export var ResearchProject_XContainer = "index-module--ResearchProject_XContainer--tIZTB";
export var ResearchProject_XContents = "index-module--ResearchProject_XContents--hRGu-";
export var ResearchProject_XLogo = "index-module--ResearchProject_XLogo--s5QUJ";