import React, { useContext, useEffect } from "react";

import { withContext } from "~context";
import { PageLayout } from "~components/templates/page-layout";
import { Catch } from "~components/home/catch";
import { Section } from "~components/templates/section";
import { Products } from "~components/home/products";
import { ExpertMember } from "~components/en/expert-member";
import { ResearchProject } from "~components/en/research-project";
import { LanguageContext } from "~context/LanguageContext";
import * as styles from "./index.module.scss";
import { Achievements } from "~components/home/achievements";
import { PipeDriveForm } from "~components/utils/pipedrive-form";

const Component = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const CONTACT_FORM_ID =
    "1FPktcZogSw0S72wUY2h4rmijAX2UqApIU1IVmchYRx0Qh03FUh6ukBhvTKFHXavh";

  useEffect(() => {
    changeLanguage("en");
  }, []);

  return (
    <PageLayout
      meta={{
        description:
          "Flatt Security, based in Tokyo, offers expert security assessments and penetration testing for software. Our seasoned professionals deliver proven, top-tier services. We also provide tools to help you internalize their security practices.",
        lang: language,
        title: "Flatt Security Inc.",
      }}
      headerProps={{
        useTransition: true,
      }}
      paddingPattern="none"
    >
      <Catch language={language} />
      <div className={styles.En_AchievementWrapper}>
        <Achievements language="en" />
      </div>
      <Section background="subtle-gray">
        <Products language={language} />
      </Section>
      <Section background="navy">
        <ExpertMember />
      </Section>
      <Section background="subtle-gray">
        <ResearchProject />
      </Section>
      <Section>
        <div className={styles.En_Contact} id="contact">
          <PipeDriveForm formID={CONTACT_FORM_ID} />
        </div>
      </Section>
    </PageLayout>
  );
};

export default withContext(Component);
