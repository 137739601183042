import React, { FC } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const ResearchProject: FC = () => {
  const data: GatsbyResearchProjectComponentQuery = useStaticQuery(graphql`
    query ResearchProjectComponent {
      link_to_x_bg: file(relativePath: { eq: "en/catch_en.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      x_logo: file(relativePath: { eq: "en/x_logo.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <section className={styles.ResearchProject}>
      <h2 className={styles.ResearchProject_Heading}>Featured Projects</h2>
      <div className={styles.ResearchProject_ListSection}>
        <ul className={styles.ResearchProject_List}>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="https://speakerdeck.com/flatt_security/are-you-content-with-our-current-attacks-on-content-type"
              className={styles.ResearchProject_ListItemLink}
            >
              <p className={styles.ResearchProject_Category}>Conference</p>
              <p className={styles.ResearchProject_Title}>
                BSides Las Vages 2024: Are you content with our current attacks
                on Content-Type?
              </p>
            </Link>
          </li>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="/research/posts/beyond-the-limit-expanding-single-packet-race-condition-with-first-sequence-sync/"
              className={styles.ResearchProject_ListItemLink}
            >
              <p className={styles.ResearchProject_Category}>Blog</p>
              <p className={styles.ResearchProject_Title}>
                Expanding single-packet race condition with a first sequence
                sync for breaking the 65,535 byte limit
              </p>
            </Link>
          </li>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="/research/posts/batbadbut-you-cant-securely-execute-commands-on-windows/"
              className={styles.ResearchProject_ListItemLink}
            >
              <p className={styles.ResearchProject_Category}>Blog</p>
              <p className={styles.ResearchProject_Title}>
                BatBadBut: You can't securely execute commands on Windows
              </p>
            </Link>
          </li>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="/research/posts/bypassing-dompurify-with-good-old-xml/"
              className={styles.ResearchProject_ListItemLink}
            >
              <p className={styles.ResearchProject_Category}>Blog</p>
              <p className={styles.ResearchProject_Title}>
                Bypassing DOMPurify with good old XML
              </p>
            </Link>
          </li>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="/reports/210401_pwn2own/"
              className={styles.ResearchProject_ListItemLink}
            >
              <p className={styles.ResearchProject_Category}>Report</p>
              <p className={styles.ResearchProject_Title}>
                Pwn2Own 2021 Report: Ubuntu Desktop Exploit
              </p>
            </Link>
          </li>
          <li className={styles.ResearchProject_ListItem}>
            <Link
              to="/research/posts/finding-an-unseen-sql-injection-by-bypassing-escape-functions-in-mysqljs-mysql/"
              className={styles.ResearchProject_ListItemLink}
            >
              <div>
                <p className={styles.ResearchProject_Category}>Blog</p>
              </div>
              <p className={styles.ResearchProject_Title}>
                Finding an unseen SQL Injection by bypassing escape functions in
                mysqljs/mysql
              </p>
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.ResearchProject_ButtonWrapper}>
        <Link to="/research">
          <Button buttonSize="Large" className={styles.ResearchProject_Button}>
            See research blog
          </Button>
        </Link>
        <Link to="/cve">
          <Button buttonSize="Large" className={styles.ResearchProject_Button}>
            See our CVEs
          </Button>
        </Link>
      </div>
      <div className={styles.ResearchProject_X}>
        <Link to="https://twitter.com/flatt_sec_en">
          <OptionalGatsbyImage
            className={styles.ResearchProject_XBg}
            data={data.link_to_x_bg}
            alt="link_to_x"
          />
          <div className={styles.ResearchProject_XContainer}>
            <img
              className={styles.ResearchProject_XLogo}
              src={data.x_logo?.publicURL ?? ""}
              alt=""
            />
            <p className={styles.ResearchProject_XContents}>
              Follow Flatt Security on X for the latest news.
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
};
